import React from 'react';
import '../assets/css/styles.css';
import Navbar from '../components/Navbar'
import Contact from '../components/Contact'



const ContactMe = () => {
  return (
    <>
    <Navbar/>
    <Contact/>
    </>
  )
}

export default ContactMe