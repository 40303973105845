import React from 'react';
import '../assets/css/styles.css';


const Contact = () => {
  return (
    <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className='intro-title'>Coming Soon...</h1>

      </div>
  )
}

export default Contact